.page-wrapper,
.custom-participant-page {
  .entry-form {
    .form-floating {
      position: relative;
      label {
        background-color: #fff;
        left: 38px;
        top: 11px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
        padding: 0 0.625rem;
        font-size: 1.063rem;
        color: #6a7084;
        font-family: $font-medium;
        height: auto;
        transform: none;
        opacity: 1;
      }
      i {
        position: absolute;
        left: 0;
        font-size: 1.063rem;
        width: 50px;
        height: 50px;
        display: flex;
        top: 1px;
        z-index: 1;
        align-items: center;
        justify-content: center;
        color: #9ca1b0;
      }
      .icon-seen {
        left: auto;
        right: 0;
      }
      input {
        border: 1.3px solid #c1c6d5;
        box-sizing: border-box;
        border-radius: 5px;
        height: 50px;
        margin-bottom: 1.438rem;
        font-size: 1.063rem;
        color: $secondary;
        padding: 0.6rem 1rem;
        padding-left: 3rem;
        &:focus,
        &:not(:placeholder-shown) {
          box-shadow: none;
          border: 2px solid $primary;
          ~ label {
            font-family: $font-semibold;
            font-size: 0.875rem;
            color: $primary;
            top: -11px;
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;
            opacity: 1;
          }
        }
      }
      .form-select {
        border: 1.3px solid #c1c6d5;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 0.6rem 1rem;
        padding-left: 3rem;
        height: 50px;
        margin-bottom: 1.25rem;
        font-size: 1.063rem;
        color: $secondary;
        background-position: right 1.2rem center;
        background-size: 13px 12px;
        &:focus,
        &:not([value='']) {
          box-shadow: none;
          border: 2px solid $primary;
          &:valid {
            ~ label {
              font-family: $font-semibold;
              font-size: 0.875rem;
              color: $primary;
              top: -11px;
              transition: 0.2s ease all;
              -moz-transition: 0.2s ease all;
              -webkit-transition: 0.2s ease all;
              opacity: 1;
            }
          }
        }
      }
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        display: none;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        display: none;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        display: none;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        display: none;
      }
    }
    .form-check {
      display: flex;
      align-items: center;
      .form-check-input {
        width: 1.438rem;
        height: 1.438rem;
      }
      .form-check-label {
        margin-left: 1rem;
      }
    }
  }
}

.logos {
  margin: 0 auto;
  .footer-logo {
    margin: 4.3rem 0 1.2rem 0;
    display: flex;
    justify-content: center;
    img {
      max-width: 7.5rem;
    }
  }
}
footer {
  .footer-logo {
    margin: 2.5rem 0;
    display: flex;
    justify-content: center;
    img {
      max-width: 7.5rem;
    }
  }
}
.main-content {
  padding: 0 2.188rem;
}
.inner {
  .entry-para {
    p {
      font-size: 1.063rem;
      line-height: 1.688rem;
      margin-bottom: 2.5rem;
    }
  }
  .entry-thumb {
    width: 9.125rem;
    height: 9.125rem;
    overflow: hidden;
    background-color: $light-gray;
    border-radius: 10px;
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
  .entry-content {
    h1 {
      font-size: 1.375rem;
      line-height: 2rem;
      color: $dark-medium;
      font-family: $font-bold;
    }
    p {
      font-size: 1rem;
      line-height: 1.563rem;
    }
  }
  .download {
    .click-here {
      background-color: #e9f4fc;
      padding: 2.188rem;
      text-align: center;
      h2 {
        line-height: 2rem;
      }
      p {
        font-size: 1.063rem;
      }
      strong {
        font-family: $font-bold;
      }
    }
    .card {
      padding: 1.5rem 1.9rem 1.2rem 1.9rem;
      border: 1px solid #c1c6d4;
      border-radius: 5px;
      min-height: 100%;
      text-align: center;
      .card-thumb {
        margin: 0 auto;
        margin-bottom: 1.375rem;

        img {
          max-width: 2rem;
        }
      }
      .card-content {
        p {
          font-size: 1.063rem;
          line-height: 1.275rem;
          font-family: $font-semibold;
          margin: 0;
        }
      }
    }
  }
  .scene-list {
    .form-check {
      background: #ffffff;
      border: 1px solid #c1c6d4;
      border-radius: 5px;
      padding: 1.18rem 2.2rem;
      label {
        font-size: 1.188rem;
        font-family: $font-semibold;
        width: 100%;
        margin-left: 10px;
      }
      .form-check-input {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 21px;
        height: 21px;
        border-width: 2px;
        border-color: #c1c6d4;
        &:focus {
          box-shadow: none;
        }
        &:checked {
          background-color: $white;
          border-color: $primary;
          &::after {
            opacity: 1;
          }
        }
        &checked {
          background-color: $white;
          border-color: $primary;
          &::after {
            opacity: 1;
          }
        }
        &::after {
          content: '';
          width: 11px;
          height: 11px;
          background: $primary;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
        }
      }
      &.active {
        background-color: #e9f4fc;
        border-color: #a6d9ff;
      }
    }
    .add-btn {
      cursor: pointer;
      img {
        max-width: 3rem;
      }
    }
  }
}
