.btn{
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    &:hover,&:focus,&:active{
        color: $white;
        outline: none;
        box-shadow: none !important;
    }
    &:hover{
        color: $white;
        transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
    }
}
.btn-link-primary{
    display: flex;
    align-items: center;
    padding: .60rem 1.11rem;
    font-size: 0.938rem;
    font-family: $font-semibold;
    color: $primary;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    line-height: 1.188rem;
    &:hover{
        color: $primary;
    }
}
.btn-primary,.btn-danger{
    color: $white;
    font-size: 1.188rem;
    font-family: $font-semibold;
    line-height: 1.125rem;
    border-radius: 5px;
    padding: 1.125rem 1.16rem;
    display: inline-flex;
    align-items: center;
}
.btn-primary-light{
    color: $primary;
    font-size: 1.063rem;
    font-family: $font-medium;
    line-height: 1.125rem;
    border-radius: 5px;
    padding: 1.125rem 1.16rem;
    display: inline-flex;
    align-items: center;
    background-color: $primary-lighten;
    &:hover,&:focus{
        color: $primary;
        background-color: #d3ecff;
    }
}

.btn-link{
    font-family: $font-semibold;
}
