$theme: #1A9AFA;
$primary: #1A9AFA;
$secondary: #000000;
$content: #4B5060;
$light-primary :#CDEEFE;
$primary-lighten:#E9F4FC;
$danger: #EF472E;
$lightyellow :#fffbcc;
$success:#0c6638;
$white:#fff;
$light-gray:#C4C4C4;;
$light:#FAFAFA;
$dark:#313131;
$dark-medium:#14122E;
$ultra-light:#D2D2D2;
$lighten:#F6F6F6;
$theme-colors: (
  "theme": $theme,
    "primary": $primary,
    "secondary": $secondary,
    "danger": $danger,
    "success":$success,
    "white" : $white,
    "content": $content,
);
  
$custom-colors: (
  "light-gray" :$light-gray,
  "lightyellow" :$lightyellow,
  "light": $light,
  "dark":$dark,
  "dark-medium":$dark-medium,
  "ultra-light":$ultra-light,
  "lighten":$lighten,
  "light-primary":$light-primary,
  "primary-lighten":$primary-lighten
);
$theme-colors: map-merge($theme-colors, $custom-colors);

$base-font:'Acumin Pro', sans-serif;
$font-light: 'Acumin-light';
  $font-regular: 'Acumin-regular';
  $font-medium: 'Acumin-medium';
  $font-semibold: 'Acumin-semibold';
  $font-bold: 'Acumin-bold';

  $weights:(
    'medium': $font-medium,
    'bold': $font-bold,
    'light': $font-light,
    'regular':$font-regular,
    'semibold': $font-semibold
  );

  @each $name, $weight in $weights {
    .fw-#{$name} {
      font-family: $weight !important;
    }
  }

//==================Container Breakpoint=============//
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1200px) !default;
