@import 'node_modules/bootstrap/scss/functions';

@import 'node_modules/bootstrap/scss/mixins';

@import '../variables/variables';
@import '../variables/mixins';

@import 'node_modules/bootstrap/scss/variables';
@import 'common';
@import 'node_modules/bootstrap/scss/bootstrap';

//Bootstrap
@import 'bootstrap';

@import './utilities/utilities';
@import './components/components';
@import 'pages/pages';
@import 'fonts';
@import 'developer.scss';

html,
body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: $font-regular;
  color: $content;
}
.page-wrapper {
  width: 100%;
  flex: 1;
  align-self: stretch;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  aside {
    height: 100%;
    background-color: $secondary;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    position: relative;
    .sidebar {
      width: 31.25rem;
      height: 100%;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      background-color: $white;
      position: relative;
    }
  }
}

ul {
  list-style: none;
}
