@import '../variables/variables';

main {
  height: 100vh !important;
  .nav-left {
    .navbar-brand {
      margin-right: 0.5rem !important;
    }
  }
}
.not-allowed {
  * {
    opacity: 0.65;
    cursor: not-allowed !important;
  }
  &:hover {
    cursor: not-allowed !important;
  }
}
.login-btn.isDisabled {
  opacity: 0.65;
  cursor: not-allowed !important;
  color: #ffffff;
}
.disabled-link {
  pointer-events: none !important;
}
.custom-clickable {
  cursor: pointer !important;
}
// .custom-login-page,
// .cutsom-signin-page {
//   input {
//     padding-top: 1rem !important;
//   }
// }
.custom.form-floating-wrapper {
  .form-floating {
    label {
      background-color: #e9f4fc;
    }
    input {
      height: 50px;
      background-color: #e9f4fc;
      &:focus,
      &:not(:placeholder-shown) {
        padding-top: 1rem;
        ~ label {
          top: -11px;
          background-color: #e9f4fc;
        }
      }
    }
  }
}

.download-for-os {
  color: #4b5060 !important;
  text-decoration: none !important;
}
.custom-entry-title {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  margin-bottom: 1rem !important;
}
#new-scene-form {
  .user-floating-input {
    height: 3rem !important;
  }
}
.modal {
  padding: 1rem;
}
.form-floating {
  img {
    left: auto;
    right: 14px;
    max-width: 1.2rem;
    position: absolute;
    top: 0;
    width: 50px;
    height: 50px;
  }
}
.form-label {
  width: auto !important;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.licence-page {
  li {
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem 1rem 1rem 1rem;
    h3 {
      font-size: 1.25rem;
      color: #14122e;
      line-height: 1.625rem;
      font-family: 'Acumin-semibold';
      margin-top: 0.5rem;
    }
  }
  .btn-custom-edit-plan {
    font-size: 1.063rem;
    line-height: 1.625rem;
  }
}
// .scene-list-wrapper {
//   height: 200px;
//   overflow-y: scroll;
// }
.dashboard-page {
  .fixed-bottom {
    .custom-container {
      width: 31.25rem;
      background-color: #ffffff;
      .btn {
        border-radius: 0px;
      }
    }
  }
}
.custom-question-icon {
  width: 12px;
  margin-top: -5px;
}
.user-floating-input{
  padding: 0.6rem;
}
.search-wrapper{
  margin-right: 0px;
  .scene-input{
    padding: 0.6rem;
  }
  .search-image-wrapper{
    border-radius: 0.3rem;
    background-color: #1a9afb;
    .custom-search-icon{
      // width: 1rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  main {
    header {
      margin-bottom: 0rem;
      nav {
        .navbar-brand {
          img {
            max-width: 6.5rem;
          }
        }
        .nav-left {
          .navbar-brand {
            margin-right: 0.5rem !important;
          }
        }
        .nav-right {
          .navbar-toggler {
            padding: 0rem;
          }
        }
      }
    }
    .entry-heading {
      margin-top: 1.2rem;
      margin-bottom: 2rem;
    }
    .btn-primary,
    .btn-danger,
    .btn-primary-light {
      padding: 0.7rem 0.7rem;
    }
    .download {
      .entry-title {
        font-size: 2.2rem;
      }
    }
  }
}

.under-maintenance-page {
  .text-primary {
    color: #707070 !important;
  }
}
.custom-text-maintenance {
  background-color: #d53d28 !important;
  color: #ffffff !important;
}
.custom-btn-sm{
  padding: 0.6rem 2rem !important;
  font-size: 1.1rem !important;
}

.custom-form-check {
  margin: 0.01rem;
  // margin-bottom: 1.2rem;
  padding: 1.1rem 0rem;
  align-items: center;
  border: 1px solid #c1c6d4;
  border-radius: 5px;
  .preview-image-wrapper{
    padding-right: 0px;
    .preview-image{
      border-radius: 0.2rem;
      background: #f1f1f1;
      &.zoom {
        transition: transform 0.2s;
        margin: 0 auto;
      }
      &.zoom:hover {
        transform: scale(1.5);
      }
    }
  }
  .name-wrapper{
    padding: 0px;
    label {
      text-transform: capitalize;
      font-size: 1.188rem;
      font-family: $font-semibold;
      padding-left: 1.2rem;
      word-break: break-all;
    }
  }
  .form-check-input {
    margin-top: 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21px;
    height: 21px;
    border-width: 2px;
    border-color: #c1c6d4;
    &:focus {
      box-shadow: none;
    }
    &:checked {
      background-color: $white;
      border-color: $primary;
      &::after {
        opacity: 1;
      }
    }
    &checked {
      background-color: $white;
      border-color: $primary;
      &::after {
        opacity: 1;
      }
    }
    &::after {
      content: '';
      width: 11px;
      height: 11px;
      background: $primary;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
    }
  }
  &.active {
    background-color: #e9f4fc;
    border-color: #a6d9ff;
  }
}