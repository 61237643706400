

@font-face {
    font-family: 'Acumin-light';
    src: url('../fonts/AcuminPro-Light.eot');
    src: url('../fonts/AcuminPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AcuminPro-Light.woff') format('woff'),
        url('../fonts/AcuminPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Acumin-regular';
    src: url('../fonts/AcuminPro-Regular.eot');
    src: url('../fonts/AcuminPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AcuminPro-Regular.woff') format('woff'),
        url('../fonts/AcuminPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Acumin-medium';
    src: url('../fonts/AcuminPro-Medium.eot');
    src: url('../fonts/AcuminPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AcuminPro-Medium.woff') format('woff'),
        url('../fonts/AcuminPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Acumin-semibold';
    src: url('../fonts/AcuminPro-Semibold.eot');
    src: url('../fonts/AcuminPro-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AcuminPro-Semibold.woff') format('woff'),
        url('../fonts/AcuminPro-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Acumin-bold';
    src: url('../fonts/AcuminPro-Bold.eot');
    src: url('../fonts/AcuminPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AcuminPro-Bold.woff') format('woff'),
        url('../fonts/AcuminPro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}