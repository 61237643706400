header {
  margin-bottom: 1.4rem;
  padding: 1.15rem 1.188rem;
  nav {
    .navbar-brand {
      img {
        max-width: 11.3rem;
        @include media-breakpoint-down(sm) {
          max-width: 7rem;
          margin: 0.7rem 0;
        }
      }
      &:first-child {
        border-right: 1px solid #ededed;
        margin-right: 1.2rem;
        padding-right: 0.8rem;
      }
    }
    .nav-left {
      float: left;
    }
    .nav-right {
      float: right;
      display: flex;
      align-items: center;
      .name {
        font-size: 1.063rem;
        line-height: 1.275rem;
        font-family: $font-semibold;
        margin-right: 1.563rem;
      }
    }
    .navbar-toggler {
      border: none;
      img {
        max-width: 1.5rem;
        @include media-breakpoint-down(sm) {
          max-width: 1.2rem;
        }
      }
      &::after {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .dropdown-menu[data-bs-popper] {
      left: auto;
      right: 0;
    }
    .dropdown-menu {
      border: 1px solid rgba(85, 96, 138, 0.1);
      box-sizing: border-box;
      box-shadow: 0px 3px 3px rgba(197, 197, 197, 0.25);
      .dropdown-item {
        color: $secondary;
        padding: 0.5rem 1rem;
      }
      span {
        font-size: 1.063rem;
        display: block;
        padding: 0.5rem 1rem;
        font-family: $font-semibold;
        color: $primary;
      }
    }
  }
}
