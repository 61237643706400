.modal {
  position: absolute;
  .modal-header {
    padding: 1.563rem 1.4rem 0 1.875rem;
    border: none;
    .btn-close {
      background: url('../../images/close.svg');
      width: 8px;
      height: 8px;
      background-size: cover;
      opacity: 1;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .modal-body {
    .modal-title {
      font-size: 1.75rem;
      line-height: 2.1rem;
      color: $secondary;
      font-family: $font-bold;
      margin-bottom: 1rem;
    }
    padding: 1rem 2.875rem 2.5rem 1.875rem;
    p {
      font-size: 1.063rem;
      line-height: 1.688rem;
      margin-bottom: 0;
    }
  }
}
