//Inner Title and Heading
.entry-title {
  font-size: 2.25rem;
  line-height: 3.125rem;
  font-family: $font-bold;
  color: $secondary;
  margin-bottom: 2rem;
}
.entry-heading {
  font-size: 1.375rem;
  line-height: 1.65rem;
  font-family: $font-regular;
  color: $secondary;
  margin-bottom: 2.5rem;
}

//new
$modal-header-border-color: rgba(0, 0, 0, 0.1);
$modal-footer-border-color: rgba(0, 0, 0, 0.1);
$modal-content-border-radius: 10px;
$modal-header-padding: 1.313rem 1.875rem;
$modal-lg: 737px;
$modal-md: 577px;
$modal-sm: 430px;
$form-select-indicator: url('.../../../images/down.svg');
